body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Avoids scroll bars appearing */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#appTitleBar {
  display: flex;
}

#appTitleBar button {
  width: 55px;
  height: 55px;
  margin-top: 5px;
}

a {
  color: #28660a;
}

a:visited {
  color: #487C2F;
}

#footer {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 20px 0;
}
#footer a {
  min-height: 20px;
  padding: 20px 40px;
}

.loader {
  display: inline-block;
  width: 64px;
  height: 64px;
  padding: 20%;
}
.loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #555;
  border-color: #555 transparent #555 transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#tableContainer {
  margin: 16px;
}

.heading {
  margin-top: 15px!important;
  font-weight: bold!important;
  font-size: 0.8125rem!important;
}

/* Name column */
.schoolRow td:nth-child(3) {
  white-space: nowrap!important;
  padding-right: 20px;
  color: #28660a;
}

.buttonHighlight {
  background-color: transparent!important;
}

.buttonDefault {
  /* Partially transparent version of primary light palette colour */
  background-color: #58943a2c!important;
}

@media screen and (max-width: 959px) {
  #logo img {
    width: 140px!important;
  }

  #tableContainer {
    margin: 2px 0 10px;
  }

  table {
    display: flex!important;
  }

  tbody {
    display: flex!important;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  tr { /* May need to add a class to this row to avoid it affecting future elements */
    height: unset!important; /* Allow height to stretch as needed */
  }

  .schoolRow {
    display: flex!important;
    width: 100%;
    height: unset!important; /* Allow height to stretch as needed */
    border-width: 1px!important;
    padding: 15px 0;
    flex-wrap: wrap;
    flex-grow: 1;
    position: relative;
  }

  /* The > button to expand the row */
  .schoolRow td:first-child {
    position: absolute;
    right: 0px;
    left: 80%;
    bottom: 0px;
    top: 76%;
    font-weight: bold;
    background-color: #ebfce188;
    padding-left: 5%!important;
  }

  .schoolRow td {
    display: flex;
    width: auto!important;
    height: unset;
    border: none;
    background-color: unset;
    padding: 10px;
    padding-left: 5%;
    overflow: hidden; /* Ensures odd/even cells don't stretch and mismatch */
    white-space: normal; /* Allows wrapping of cell texts */

  }

  .schoolRow td a {
    font-weight: bold;
    font-size: 105%;
  }

  /* Left-hand column containing labels */
  .schoolRow td:nth-child(even) {
    flex-basis: 25%;
    font-size: 85%;
    text-transform: lowercase;
    padding-right: 2px;
  }

  /* Right-hand column containing data */
  .schoolRow td:nth-child(odd) {
    flex-basis: 63%;
    padding-left: 2%;
  }

  /* Hide the Name label */
  .schoolRow td:nth-child(2) {
    display: none;
  }
  /* Set Name block to be wider */
  .schoolRow td:nth-child(3) {
    width: 100%;
    padding-left: 9%;
    padding-right: 20%;
  }

  .schoolRow td:last-child {
    padding-right: 10px;
  }

  #footer {
    flex-direction: column;
  }

  .aboutClose button {
    padding-top: 10px!important;
    padding-bottom: 10px!important;
  }

  .cardHeading {
    padding-bottom: 0!important;
  }

}
